
export default {
  inheritAttrs: false,

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    colorBackground: {
      type: String,
      default: "--color-black-50",
    },
  },
};
